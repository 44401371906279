import React from "react";
import { Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import styles from "./layout.module.css";

const ListLink = props => (
    <li style={{ display: `inline-block`, marginRight: `1rem` }}>
        <Link className={styles.link} to={props.to}>{props.children}</Link>
    </li>
)

export default function Layout(props) {
    const data = useStaticQuery(graphql`
        query {
            meImage: file(relativePath: { eq: "memoji.png" }) {
                childImageSharp {
                    fluid(maxWidth: 100) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `);

    let showHeader = props.showHeader;
    let year = new Date().getFullYear();
    
    if (showHeader === undefined) {
        showHeader = true;
    }

    return (
        <div style={{ margin: `0 auto`, height: '100%', maxWidth: '90%' }}>
            <header className={styles.header} style={{ height: '50px', alignItems: 'center', display: 'flex' }}>
                { showHeader === true &&
                    <Link to="/" style={{ textDecoration: 'none', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                        <Img style={{ width: '30px', marginRight: '5px' }} fluid={data.meImage.childImageSharp.fluid} />
                        <h3 className={styles.name}>Dylan van den Brink</h3>
                    </Link>
                }
                <div style={{ flex: 1 }}></div>
                <ul style={{ listStyle: `none` }}>
                    <ListLink to="/">Home</ListLink>
                    {/* <ListLink to="/cv">CV</ListLink> */}
                    <ListLink to="/projects">Projects</ListLink>
                </ul>
            </header>
            { props.children }
            <footer style={{ height: '50px', textAlign: 'center' }}>
                <h4>© {year} Dylan van den Brink</h4>
            </footer>
        </div>
    )
}